// Flatly 4.5.3
// Bootswatch

//
// Color system
//

$white: white !default;
$gray-100: #f9f9f9 !default;
$gray-200: #f2f2f2 !default;
$gray-300: #ededed !default;
$gray-400: #ced4da !default;
$gray-500: #b4bcc2 !default;
$gray-600: #95a5a6 !default;
$gray-700: #7b8a8b !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black: #1e1010 !default;

$blue: #2e30e5 !default;
$indigo: #676784 !default;
$purple: #807be4 !default;
$pink: #ff9fd4 !default;
$red: #f81958 !default;
$orange: #ffa87d !default;
$yellow: #faff29 !default;
$green: #a8d29b !default;
$teal: #e2eb76 !default;
$cyan: #3498db !default;

$primary: $blue !default;
$secondary: $yellow !default;
$success: $green !default;
$info: $red !default;
$warning: $orange !default;
$danger: $red !default;
$light: $gray-200 !default;
$dark: $black !default;

// Links

$link-color: $success !default;

// Border radius

$border-radius-sm: 0px;
$border-radius: 0px;
$border-radius-lg: 0px;

// Fonts

@font-face {
  font-family: "Basis Grotesque";
  src: local("Basis Grotesque"),
    url(./fonts/basis-grotesque-regular.ttf) format("opentype");
  font-weight: 200;
}

@font-face {
  font-family: "Basis Grotesque";
  src: local("Basis Grotesque"),
    url(./fonts/BasisGrotesquePro-Bold.ttf) format("opentype");
  font-weight: 300;
}

@font-face {
  font-family: "Basis Grotesque Pro";
  src: local("Basis Grotesque Pro"),
    url(./fonts/BasisGrotesquePro-Regular.ttf) format("opentype");
  font-weight: 400;
}

@font-face {
  font-family: "Basis Grotesque Mono";
  src: local("Basis Grotesque Mono"),
    url(./fonts/basis-grotesque-mono-regular.ttf) format("opentype");
  font-weight: 200;
}

@font-face {
  font-family: "Basis Grotesque Mono";
  src: local("Basis Grotesque Mono"),
    url(./fonts/basis-grotesque-mono-bold.ttf) format("opentype");
  font-weight: "bold";
}

@font-face {
  font-family: "SuisseIntl";
  src: local("SuisseIntl"), url(./fonts/SuisseIntl-Black.otf) format("opentype");
}

@font-face {
  font-family: "Cooper Std";
  src: local("Cooper Std"), url(./fonts/CooperStdBlack.ttf) format("opentype");
}

$font-family-sans-serif: "Basis Grotesque", "SuisseIntl", -apple-system,
  BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif,
  "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !default;

$font-size-base: 1rem !default;
$font-size-sm: 0.8rem !default;
$font-size-lg: 1.2rem !default;

// Other

$border-width: 4px;
$spacer: 2rem !default;

$link-decoration: none;
$link-hover-decoration: none;
$link-hover-color: $pink;

// Tooltips

$tooltip-bg: $blue;
$tooltip-color: $yellow;
$tooltip-font-size: 15px;
$tooltip-padding-x: 15px;
$tooltip-padding-y: 15px;
$tooltip-opacity: 1;

// Dropdowns
$dropdown-bg: $yellow;
$dropdown-border-width: 0;

// Inputs
$input-placeholder-color: $blue;
$input-color: $yellow;
$input-focus-border-color: $blue;
$input-focus-box-shadow: none;
$input-box-shadow: none;
$input-btn-padding-y-lg: 12px;
$input-bg: transparent;

$form-select-bg: $yellow;

// Button styling
$btn-padding-y: 8px;
$btn-padding-y-lg: 8px;

// Modals
$modal-backdrop-opacity: 0.9;

$transition-collapse: height 0.1s ease !default;


$nav-pills-border-radius: 10px;
