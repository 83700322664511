@import "./theme.scss";
@import "~bootstrap/scss/bootstrap.scss";

/* Can override global css here, however if possible use the bootstrap variables instead and update
   in "./theme.scss".

   https://github.com/twbs/bootstrap-sass/blob/master/assets/stylesheets/bootstrap/_variables.scss
*/

// Allow full screen vertical styling
html,
body,
#root,
.App,
.App > div,
.dashboard-route-inner > div,
.project-route > div[tabindex] {
  height: 100%;
  font-family: "Basis Grotesque";
}

body {
  background-image: url("../images/bg.jpg");
  background-repeat: repeat;
  color: white;
}

b {
  font-weight: bold;
}

a {
  text-underline-offset: 5px;
  font-family: "Basis Grotesque Mono";
  text-underline-offset: 5px;
  text-decoration-thickness: 2px;
}

.project-route > div:not(.bg-white) {
  // There is a div created from reach-router that needs 100% height.
  height: 100%;
}

.ql-editor {
  // Used for rich text editors.
  min-height: 200px;
}

.react-toast-notifications__container {
  // Put the notification in front of bootstrap modal overlay.
  z-index: 1080 !important;
  color: black;
}

.navbar-light .navbar-toggler {
  border: none;
}

.collapsed .navbar-toggler-icon {
  background-image: url("../images/menu-icon.png") !important;
  width: 25px;
  height: 14px;
}

.navbar-toggler {
  outline: none;
}

.navbar-toggler:focus {
  outline: none;
}

/**
 * Alter bootstrap default styling
 *
 * TODO: These need to be looked at to see if we can modify the base theme.scss instead.
 * Some of these are not compatible with scss variables, and some of them are just done
 * here instead (which should be moved to theme.scss).
 */
.form-control {
  border: 4px solid #2e30e5;
  font-size: 21px;
  line-height: 32px;

  // &:focus {
  //   border: 1px solid transparent;
  //   background-color: transparent;
  //   color: #faff29;
  // }

  &.is-invalid {
    box-shadow: none !important;
  }
}

.input-group-text {
  border-radius: initial;
  background-color: #2e30e5;
  border: none;
  color: #faff29;
}

.tooltip {
  margin-bottom: 6px;
  font-family: Basis Grotesque Mono;
}

input,
textarea {
  font-family: "Basis Grotesque Pro" !important;
}

.dropdown-item:hover {
  background-color: #fff;
}

.carousel-control-prev {
  left: -60px;
}

.carousel-control-next {
  right: -60px;
}

.was-validated .form-control:invalid,
.form-control.is-invalid,
.was-validated .form-control:invalid:focus,
.form-control.is-invalid:focus {
  border-color: #ffa87d;
  background-image: none;
}

.form-label {
  // $form-label-margin-bottom does not seem to work.
  margin-bottom: 0;
}

.slick-track {
  // Can't find another way to make the slick track left-aligned when it doesn't fill up
  // the whole row.
  margin-left: 0 !important;
}

.nav-link {
  color: var(--secondary);
  font-size: 1.2rem;
}

@media (max-width: 576px) {
  .container {
    padding: 0;
  }

  .slick-list {
    overflow: inherit !important;
  }
}

.__floater__open {
  z-index: 1032!important;
}
